<template>
<div>
    <b-btn-group  size="sm">
        <b-btn pill :pressed="slide == 0" @click="slide=0" class="ml-1" variant="light"> {{$t('process-modeling')}} </b-btn>
        <b-btn pill :pressed="slide == 1" @click="slide=1" class="ml-1" variant="light"> {{ $t('architechture-connection') }} </b-btn>
        <b-btn pill :pressed="slide == 2" @click="slide=2" class="ml-1" variant="light"> {{ $t('org-structure-modeling') }}</b-btn>
        <b-btn pill :pressed="slide == 3" @click="slide=3" class="ml-1" variant="light"> {{ $t('reglament-download') }} </b-btn>
    </b-btn-group>
    <b-carousel id="carousel-1" v-model="slide" :interval="8000" background="#fff"  class="img-wrapper img-radius mt-4" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
        <b-carousel-slide>
            <template #img>
                <img class="d-block img-fluid w-100 " width="800" height="600" src="@/app/assets/img/enterprise_1.png">
            </template>
        </b-carousel-slide>
        <b-carousel-slide>
            <template #img>
                <img class="d-block img-fluid w-100" width="1024" height="480" src="@/app/assets/img/enterprise_2.png" >
            </template>
        </b-carousel-slide>
        <b-carousel-slide>
            <template #img>
                <img class="d-block img-fluid w-100" width="1024" height="480" src="@/app/assets/img/enterprise_3.png" >
            </template>
        </b-carousel-slide>
        <b-carousel-slide>
            <template #img>
                <img class="d-block img-fluid w-100" width="1024" height="480" src="@/app/assets/img/enterprise_4.png" >
            </template>
        </b-carousel-slide>
    </b-carousel>

   
</div>
</template>

<script>
export default {
    name: 'FrontendFeaturesSlider',

    data() {
        return {
            slide: 0,
            sliding: null
        };
    },

    mounted() {

    },

    methods: {
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        }
    },
};
</script>

<style lang="scss" scoped>
.img-radius {
    border: 2px none #1C6EA4;
}

.img-wrapper {
    -webkit-box-shadow: 6px 8px 27px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 6px 8px 27px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 6px 8px 27px 0px rgba(34, 60, 80, 0.2);
    border: 2px none #1C6EA4;
    border-radius: 0.75rem;
}

video {
    clip-path: inset(7px 7px);
}

.hugep {
    font-size: 20px;
}
</style>
